const CookiesReducer = (state, action) => {
    switch (action.type) {
      case "accept": {
        return {
          cookiebanner: true,
        };
      }
      case "unaccapted": {
        return {
            cookiebanner: false,
        };
      }
      default:
        return state;
    }
  };
  
  export default CookiesReducer;