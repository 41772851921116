export const DiscountData = {
    discount: {
        DE: {
            discountcode1_1: "Neukunde10",
            discountcode1_2: "neukunde10",

            discountcode2_1: "Stammkunde15",
            discountcode2_2: "stammkunde15",

            discountcode3_1: "Firma20",
            discountcode3_2: "firma20"

        },
        EN: {
            discountcode1_1: "Neukunde10",
            discountcode1_2: "neukunde10",

            discountcode2_1: "Stammkunde15",
            discountcode2_2: "stammkunde15",

            discountcode3_1: "Firma20",
            discountcode3_2: "firma20"
        }
    },
};