import React, {useState, useEffect, useContext} from 'react'
import { GoogleMap, MarkerF, OverlayViewF, OverlayView, DirectionsRenderer } from '@react-google-maps/api';
import { SourceContext } from '../../context/SourceContext';
import { DestinationContext } from '../../context/DestinationContext';
import { DistanceContext } from '../../context/DistanceContext';
import { DurationForDistanceContext } from '../../context/DurationForDistance';

const containerStyle = {
  width: '100%',
  height: '300px', // Default height
};


function GoogleMapsSection() {

const {source, setSource} = useContext(SourceContext);
const {destination, setDestination} = useContext(DestinationContext);
const [changed, setChanged] = useState(true);
const {distance, setDistance} = useContext(DistanceContext);
const {durationForDistance, setDurationForDistance} = useContext(DurationForDistanceContext);


const [center,setCenter] = useState({
  lat: 48.13743,
  lng: 11.57549
});

const [map, setMap] = React.useState(null);
const [directionRoutePoints, setDirectionRoutePoints] = useState();

useEffect(() => {
  if(source?.length!=[] && map){
    map.panTo ({
      lat:source.lat,
      lng:source.lng
    })
    setCenter({
      lat:source.lat,
      lng:source.lng
    })
    setChanged(!changed);
  } 
  if(source.length!=[]&&destination.length!=[])
  {
    directionRoute();
  }

}, [source])

useEffect(() => {
  if(destination?.length!=[] && map){
    setCenter({
      lat:destination.lat,
      lng:destination.lng
    });
    setChanged(!changed);
  } 
  if(source.length!=[]&&destination.length!=[])
  {
    directionRoute();
  }

}, [destination])

const directionRoute=()=>{
  const DirectionsService=new window.google.maps.DirectionsService();
  DirectionsService.route({
    origin:{lat:source.lat, lng:source.lng},
    destination:{lat:destination.lat, lng:destination.lng},
    travelMode:window.google.maps.TravelMode.DRIVING
  },(result, status) => {
    if(status === window.google.maps.DirectionsStatus.OK){
      // Calculate total distance
      let totalDistance = 0;
      for (let i = 0; i < result.routes[0].legs.length; i++) {
        totalDistance += result.routes[0].legs[i].distance.value;
      }
      // Convert distance from meters to desired unit (e.g., kilometers)
      totalDistance = totalDistance / 1000; // Assuming you want distance in kilometers

      // Set the distance to your context variable (setDistance)
      setDistance(totalDistance);

      // Calculate total duration
      let totalDuration = 0;
      for (let i = 0; i < result.routes[0].legs.length; i++) {
        totalDuration += result.routes[0].legs[i].duration.value;
      }
      // Convert duration from seconds to minutes
      totalDuration = totalDuration / 3600; // Assuming you want duration in minutes
      setDurationForDistance(totalDuration);
      
      setDirectionRoutePoints(result);
    }else{
      console.error('Error');
    }
  })
}

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(8)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <GoogleMap
    mapContainerStyle={containerStyle}
    center={center}
    zoom={11}
    onLoad={onLoad}
    key={changed}
    onUnmount={onUnmount}
    options={{mapId:'fad2c27218c15860'}}
  >
    {source.length!=[]?<MarkerF 
    position={{lat:source.lat, lng:source.lng}}
    icon={{url:"./assets/source.png",
    scaledSize: {width: 20, height:20}
    }}
    >
      <OverlayViewF
        position={{lat:source.lat, lng:source.lng}}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className="google-overlayview">
          <p className='text-black'>{source.label}</p>
        </div>
      </OverlayViewF>

    </MarkerF> : null}

    {destination.length!=[]?<MarkerF 
    position={{lat:destination.lat, lng:destination.lng}}
    icon={{url:"./assets/destination.png",
    scaledSize: {width: 20, height:20}
    }}
    >
      <OverlayViewF
        position={{lat:destination.lat, lng:destination.lng}}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className="google-overlayview">
          <p className='text-black'>{destination.label}</p>
        </div>
      </OverlayViewF>
    </MarkerF> : null}
    {directionRoutePoints && (<DirectionsRenderer
      directions = {directionRoutePoints}
      options={{
        polylineOptions:{
          strokeColor:'#000',
          strokeWeight:5
        },
        suppressMarkers:true
      }}
    />)}
  </GoogleMap>
  ) 
};
 
  
export default GoogleMapsSection;