import React, {useState, useEffect, useContext} from 'react'
import { GoogleMap, MarkerF, OverlayViewF, OverlayView, DirectionsRenderer } from '@react-google-maps/api';
import { SourceContext } from '../../context/SourceContext';

const containerStyle = {
  width: '100%',
  height: '300px', // Default height
};


function GoogleMapsSection2() {

const {source, setSource} = useContext(SourceContext);

const [center,setCenter] = useState({
  lat: 48.13743,
  lng: 11.57549
});

const [map, setMap] = React.useState(null);

useEffect(() => {
  if(source?.length!=[] && map){
    map.panTo ({
      lat:source.lat,
      lng:source.lng
    })
    setCenter({
      lat:source.lat,
      lng:source.lng
    })
  } 

}, [source, map])


  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(8)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <GoogleMap
    mapContainerStyle={containerStyle}
    center={center}
    zoom={11}
    onLoad={onLoad}
    onUnmount={onUnmount}
    options={{mapId:'fad2c27218c15860'}}
  >
    {source.length!=[]?<MarkerF 
    position={{lat:source.lat, lng:source.lng}}
    icon={{url:"./assets/source.png",
    scaledSize: {width: 20, height:20}
    }}
    >
      <OverlayViewF
        position={{lat:source.lat, lng:source.lng}}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div className="google-overlayview">
          <p className='text-black'>{source.label}</p>
        </div>
      </OverlayViewF>

    </MarkerF> : null}
  </GoogleMap>
  ) 
};
 
  
export default GoogleMapsSection2;