import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './navbarWeb.scss';
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import { LanguageContext } from "../../context/LanguageContext";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { TextData } from '../../source/textData';


function NavbarWeb({ scrolled }) {
    
  const { language, dispatch } = useContext(LanguageContext);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const scrollToFAQ = () => {
    const yOffset = -100; // Adjust the offset as needed
    const element = document.getElementById('faq');
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
}

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link to="/"><img alt="vipdrivers_handy_logo" className="logo-handy" src='./vipdrivers-mituntertitel-logo-black-2024-v1.png'/></Link>
      <Divider/>
      <List>
          <ListItem disablePadding>
            <Link to="/" fullWidth style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                <ListItemText primary={TextData.menu[language].item1}/>
              </ListItemButton>
            </Link>
            </ListItem>
            <ListItem disablePadding>
            <Link to="/leistungen" fullWidth style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                <ListItemText primary={TextData.menu[language].item2}/>
              </ListItemButton>
            </Link>
            </ListItem>
            {/*<ListItem disablePadding>
            <Link to="/#faq" fullWidth onClick={scrollToFAQ} style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                <ListItemText primary="FAQ"/>
              </ListItemButton>
            </Link>
            </ListItem>*/}
            <ListItem disablePadding>
            <Link to="/karriere" fullWidth style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                <ListItemText primary={TextData.menu[language].item3}/>
              </ListItemButton>
            </Link>
            </ListItem>
            <ListItem disablePadding>
            <Link to="/buchung" style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                  <ListItemText primary={TextData.menu[language].item4}/>
              </ListItemButton>
            </Link>
            </ListItem>
            <ListItem disablePadding>
            <Link to="/kontakt" style={{ textDecoration: "none", width:'100%', color: '#000000DE' }}>
              <ListItemButton>
                  <ListItemText primary={TextData.menu[language].item5}/>
              </ListItemButton>
            </Link>
            </ListItem>
        <ListItem disablePadding ><ListItemButton onClick={(e) => e.stopPropagation()}>
        {(language === 'DE') ? 
          (<Link aria-describedby={id} onClick={() => {dispatch({ type: "EN" })}} to="#" style={{ textDecoration: "none", color: '#000000DE', fontSize: '16px', width: '100%' }}> EN </Link>) 
          : 
          (<Link aria-describedby={id} onClick={() => {dispatch({ type: "DE" })}} to="#" style={{ textDecoration: "none", color: '#000000DE', fontSize: '16px', width: '100%' }}> DE </Link>) 
        }
        </ListItemButton></ListItem>

        {/*<ListItemButton onClick={(e) => {e.stopPropagation(); handleSubMenu()}}>
          <ListItemText  disablePadding primary="Hauptpunkt" />
            {openSubMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText  disablePadding primary="EN" onClick={() => {dispatch({ type: "EN" })}}/>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText  disablePadding primary="DE" onClick={() => {dispatch({ type: "DE" })}}/>
              </ListItemButton>
            </List>
        </Collapse>*/}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

    

  return (
    <div className="navbar">
      <div className="wrapper">
        <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" sx={{boxShadow:'none', backgroundColor: scrolled ? 'black' : 'transparent'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
           <Link to="/" className="navbar-logo-wrapper"><img alt="vipdrivers_logo" className="logo" src='./vipdrivers-mituntertitel-logo-white.png'/></Link>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className="items">
                <div className="item">
                    <Link to="/" style={{ textDecoration: "none" }}> {TextData.menu[language].item1} </Link>
                </div>
                <div className="item">
                    <Link to="/leistungen" style={{ textDecoration: "none" }}> {TextData.menu[language].item2} </Link>
                </div>
                {/*<div className="item">
                    <Link to="/#faq" onClick={scrollToFAQ} style={{ textDecoration: "none" }}> FAQ </Link>
                </div>*/}
                <div className="item">
                    <Link to="/karriere" style={{ textDecoration: "none" }}> {TextData.menu[language].item3} </Link>
                </div>
                <div className="item">
                    <Link to="/buchung" style={{ textDecoration: "none" }}> {TextData.menu[language].item4} </Link>
                </div>
                <div className="item">
                    <Link to="/kontakt" style={{ textDecoration: "none" }}> {TextData.menu[language].item5} </Link>
                </div>
                <div className="item" id="languagepicker"> 
                    <Link aria-describedby={id}  onClick={handleClick} to="#" style={{ textDecoration: "none" }}>{(language === 'DE') ? 'DE' : 'EN'} <ExpandMoreIcon/> </Link>
                </div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      maxWidth: '300px', // Hier können Sie die maximale Breite des Popovers einstellen
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    },}}
                  
                >
                    {(language === 'DE') ? 
                    (<Link aria-describedby={id}  onClick={() => {dispatch({ type: "EN" })}} to="#" style={{ textDecoration: "none", color: 'black', fontSize: '18px' }}> EN </Link>) 
                    : 
                    (<Link aria-describedby={id}  onClick={() => {dispatch({ type: "DE" })}} to="#" style={{ textDecoration: "none", color: 'black', fontSize: '18px' }}> DE </Link>) 
                    }
                </Popover>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
        
        </Box>
      </div>
    </div>
  );
};

export default NavbarWeb;