import { createContext, useEffect, useReducer } from "react";
import LanguageReducer from "./LanguageReducer";

const INITIAL_STATE = {
  language: JSON.parse(localStorage.getItem("language")) || 'DE',
};

export const LanguageContext = createContext(INITIAL_STATE);

export const LanguageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LanguageReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("language", JSON.stringify(state.language));
  }, [state.language]);

  return (
    <LanguageContext.Provider value={{ language: state.language, dispatch }}>
      {children}
    </LanguageContext.Provider>
  );
};