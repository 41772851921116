import { useContext } from 'react'
import React from 'react'
import { CarListData } from '../../source/carData'
import CarListItem from './CarListItem'
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectedCarContext } from '../../context/SelectedCarContext';
import { TextData } from '../../source/textData';
import { LanguageContext } from "../../context/LanguageContext";


function CarListOptions({distance}) {

  const {selectedCar, setSelectedCar} = useContext(SelectedCarContext);
  const { language } = useContext(LanguageContext);


  const handleChange = (event) => {
    setSelectedCar(event.target.value);
  };


  return (
    <div className="carlistoptions">
        <h2>{TextData.buchung[language].transportmethod.title}</h2>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedCar}
          required
          onChange={handleChange}
        >
        <Grid container spacing={2} sx={{mt: 0}}>
        {CarListData.filter(item => [2,4,5].includes(item.id))
        .map((item) => (
            <Grid sx={{ mb: 1, display: { xs: 'block', md: 'flex' }, flexDirection: { md: 'column' }}} item xs={12} md={6}>
                <CarListItem car={item} distance={distance} selectedCar={selectedCar} handleChange={handleChange}/>
            </Grid>
        ))}
        </Grid>
        </RadioGroup>
    </div>
  )
}

export default CarListOptions