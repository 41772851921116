import {React, useState, useEffect} from 'react';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import "./home.scss";
import Banner from "../../components/banner/Banner";
import {SourceContext} from '../../context/SourceContext';
import {DestinationContext} from '../../context/DestinationContext';
import Faq from '../../components/home/Faq';
import HomeContent from '../../components/home/HomeContent';
import Footer from '../../components/footer/Footer';
import FleetSection from '../../components/fleetsection/FleetSection';



const Home = () => {

  const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY >= 20);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToHash = () => {
      if (window.location.hash) {
        const yOffset = -100; // Adjust the offset as needed
        const element = document.getElementById(window.location.hash.substring(1));
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    };

    useEffect(() => {
      scrollToHash();
    }, [window.location]);

  return (
          <div className="home">
            <div className="homeContainer">
              <NavbarWeb scrolled={scrolled} />
                <Banner/>
              <HomeContent/>
              <FleetSection/>
              <Faq/>
              <Footer/>
            </div>
          </div>
    );
};
 
  
export default Home;