import {useContext} from "react";
import GoogleMapsSection from "./GoogleMapsSection";
import SearchSection from "./SearchSection";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { LanguageContext } from "../../context/LanguageContext";
import { TextData } from '../../source/textData'

const Booking = () => {

    const { language } = useContext(LanguageContext);

    return (
        <div className="booking">
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={12} md={7}>
                    <div className="banner-content">
                        <h3>{TextData.header[language].subtitle}</h3>
                        <h1>{TextData.header[language].title}</h1>
                        <p>{TextData.header[language].description}</p>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <SearchSection/>
                </Grid>
            </Grid>
        </Container>
        </div>
    );
};
 
  
export default Booking;