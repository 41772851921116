import React, {useContext} from 'react';
import './footer.scss';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TextData } from '../../source/textData';
import { LanguageContext } from "../../context/LanguageContext";
import { Link } from "react-router-dom";


function Footer() {

  const { language } = useContext(LanguageContext);

  return (
    <footer>
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}s>
                    <div className="logo-footer-wrapper">                    
                        <a href="/"><img alt="vipdrivers_footer_logo" className="logo-footer" src='./vipdrivers-mituntertitel-logo-white.png'/></a>
                    </div>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="social-wrapper">
                        <a href="https://api.whatsapp.com/send?phone=498928942438" target="_blank"><WhatsAppIcon/></a>
                        <a href="https://www.instagram.com/vipdrivers_gmbh/"><InstagramIcon/></a>
                        <a href="https://www.facebook.com/vipdriversgmbh"><FacebookIcon/></a>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{pt: 6}}>
                <Grid item xs={6} sm={3}>
                    <ul>
                        <li><h4>{TextData.footer[language].column1.title}</h4></li>
                        <li><p>{TextData.footer[language].column1.link1}</p></li>
                        <li><p>{TextData.footer[language].column1.link2}</p></li>
                        <li><p>{TextData.footer[language].column1.link3}</p></li>
                    </ul>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <ul>
                        <li><h4>{TextData.footer[language].column2.title}</h4></li>
                        <li><Link to="/impressum">{TextData.footer[language].column2.link1}</Link></li>
                        <li><Link to="/datenschutz">{TextData.footer[language].column2.link2}</Link></li>
                    </ul>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <ul>
                        <li><h4>{TextData.footer[language].column3.title}</h4></li>
                        <li><Link to="/">{TextData.footer[language].column3.link1}</Link></li>
                        <li><Link to="/leistungen">{TextData.footer[language].column3.link2}</Link></li>
                        <li><Link to="/karriere">{TextData.footer[language].column3.link3}</Link></li>
                        <li><Link to="/buchung">{TextData.footer[language].column3.link4}</Link></li>
                    </ul>
                </Grid>
                
                <Grid item xs={6} sm={3}>
                    <ul>
                        <li><h4>{TextData.footer[language].column4.title}</h4></li>
                        <li><p>{TextData.footer[language].column4.link1}</p></li>
                        <li><p>{TextData.footer[language].column4.link2}</p></li>
                        <li><p>{TextData.footer[language].column4.link3}</p></li>
                        <li><p>{TextData.footer[language].column4.link4}</p></li>
                    </ul>
                </Grid>
            </Grid>
            <div className="footer-copyright">
                <p>{TextData.footer[language].lastrow.left}</p>
                <p><a href="http://monsec.de/">{TextData.footer[language].lastrow.right}</a></p>
            </div>
        </Container>
    </footer>
  )
}

export default Footer