import React, {useState, useContext} from 'react';
import './kontakt.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { TextData } from '../../source/textData'
import { MessagesData } from '../../source/messagesData'
import { LanguageContext } from "../../context/LanguageContext";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';


const mapContainerStyle = {
    width: '100%',
    height: '500px',
};
  
const center = {
    lat: 40.712776,
    lng: -74.005974,
 };
  

function Kontakt() {

const { language } = useContext(LanguageContext);

const initialFormData = {
    name: '',
    email: '',
    telefon: '',
    message: ''
};

const [submission, setSubmission] = useState(null);
const [formData, setFormData] = useState(initialFormData);


const handleSubmissionMessage = () => {
    if(formData.name ==="" || formData.email === "" || formData.telefon === "" ||formData.message === ""){
        setSubmission(null);
    }
}

const handleSubmit = (event) => {
    event.preventDefault();

    axios.post('https://api.vipdrivers.de/', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log('Form submitted successfully');
        setFormData(initialFormData);
        setSubmission(true);
    })
    .catch(error => {
        setSubmission(false);
    });
};

const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
    }));
};

  return (
          <div className="kontakt">
              <NavbarWeb scrolled={true}/>
              <div className="kontaktContainer">
              <Container maxWidth="lg" >
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', paddingBottom: '80px' }}> 
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <h2>{TextData.contact[language].title}</h2>
                            <div className="textWrapper">
                                <p>{TextData.contact[language].description1}</p>
                                <p>{TextData.contact[language].description2}</p>
                                <p className="space-bottom-opening">{TextData.contact[language].description3}</p>
                                <p>{TextData.contact[language].description4}</p>
                                <p>{TextData.contact[language].description5}</p>
                                <p className="space-bottom-opening">{TextData.contact[language].description6}</p>
                                
                                <b>{TextData.contact[language].description7}</b>
                                <div className="opening-table">
                                    <p>{TextData.contact[language].description8}</p>
                                    <p>{TextData.contact[language].description9}</p>
                                </div>
                                <div className="opening-table">
                                    <p>{TextData.contact[language].description10}</p>
                                    <p>{TextData.contact[language].description11}</p>
                                </div>
                                <div className="opening-table">
                                    <p>{TextData.contact[language].description12}</p>
                                    <p>{TextData.contact[language].description13}</p>
                                </div>
                            </div>
                            
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                        <div className="bewerbung-form-wrapper">
                                <form onSubmit={handleSubmit}>
                                <TextField
                                    name="name"
                                    required
                                    label={TextData.contact[language].textfield1}
                                    fullWidth
                                    value={formData.name}
                                    onChange={handleChange}
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="telefon"
                                    required
                                    label={TextData.contact[language].textfield2}
                                    fullWidth
                                    value={formData.telefon}
                                    onChange={handleChange}
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="email"
                                    required
                                    label={TextData.contact[language].textfield3}
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleChange}
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="message"
                                    required
                                    label={TextData.contact[language].textfield4}
                                    fullWidth
                                    value={formData.message}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    sx={{mb:3, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <FormControlLabel sx={{alignItems: 'start'}} control={<Checkbox sx={{paddingTop: '1px'}} required/>} label={MessagesData.contact[language].dsgvo} />
                                    <Button onClick={handleSubmissionMessage} type="submit" variant="contained" sx={{ mt: 4,
                                    '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                                    '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                                    color: 'black', bgcolor: '#DBB47E', padding: '8px 32px', boxShadow: 'none' }}>{TextData.contact[language].button}</Button>
                                    {submission === true ? (
                                        <p className="kontakt-success">{MessagesData.contact[language].success}</p>
                                    ) : submission === false ? (
                                        <p className="kontakt-error">{MessagesData.contact[language].error}</p>
                                    ) : null}
                                </form>
                            </div>
                        </Grid>
                    </Grid>                            
              <iframe title="VIP Driver's GmbH Location Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2654.873815995428!2d11.568646699999999!3d48.2860434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xab29eed556bfdeb%3A0x30963a88c259903c!2sVIP%20Driver&#39;s%20GmbH!5e0!3m2!1sde!2sde!4v1713181640486!5m2!1sde!2sde" width="100%" height="250" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </Container>
              </div>

              <Footer/>
        </div>
  )
}

export default Kontakt