import React, {useState, useContext} from 'react';
import './bewerbung.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { TextData } from '../../source/textData'
import { LanguageContext } from "../../context/LanguageContext";
import { MessagesData } from '../../source/messagesData'
import axios from 'axios';


function Bewerbung() {

const initialFormData = {
    name: '',
    email: '',
    telefon: '',
    message: '',
    file: null
};

const initialFormData2 = {
    name: '',
    email: '',
    telefon: '',
    message: '',
};

const [formData, setFormData] = useState(initialFormData);
const [submission, setSubmission] = useState(null);
const { language } = useContext(LanguageContext);

const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
        ...formData,
        file: file
    });
};

const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value
    });
};

const handleSubmissionMessage = () => {
    if (formData.email === "" || formData.telefon === "" || formData.message === "" || formData.vorname === "" || formData.nachname === "") {
        setSubmission(null);
    }
}
 
const handleSubmit = (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('telefon', formData.telefon);
    formDataToSend.append('message', formData.message);
    // Append the file to FormData if it exists
    if (formData.file) {
        formDataToSend.append('file', formData.file); // Append the file directly to FormData
    }

    axios.post('https://bewerbung.vipdrivers.de/', formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data for FormData
            }
    })
    .then(response => {
        console.log('Form submitted successfully');
        setFormData(initialFormData2);
        setSubmission(true);
    })
    .catch(error => {
        setSubmission(false);
    });
};


  return (
        <div className="bewerbung">
              <NavbarWeb scrolled={true}/>
              <div className="bewerbungContainer">
              <Container maxWidth="lg" >
                    <div className="zurueck">
                        <Link to="/karriere">
                            <ArrowBackIcon/>
                            <h4>{TextData.carreer2[language].back}</h4>
                        </Link>
                    </div>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
                        <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column' }}>
                            <h1 className="bewerbung-main-heading">{TextData.carreer2[language].title}</h1>
                            <b>{TextData.carreer2[language].description1}</b>
                            <ul>
                                <li>{TextData.carreer2[language].description2}</li>
                                <li>{TextData.carreer2[language].description3}</li>
                                <li>{TextData.carreer2[language].description4}</li> 
                                <li>{TextData.carreer2[language].description5}</li>
                            </ul>
                            
                            <b>{TextData.carreer2[language].description6}</b>
                            <ul>
                                <li>{TextData.carreer2[language].description7}</li>
                                <li>{TextData.carreer2[language].description8}</li>
                                <li>{TextData.carreer2[language].description9}</li>
                                <li>{TextData.carreer2[language].description10}</li>
                            </ul>
                            
                            <b>{TextData.carreer2[language].description11}</b>
                            <ul>
                                <li>{TextData.carreer2[language].description12}</li>
                                <li>{TextData.carreer2[language].description13}</li>
                                <li>{TextData.carreer2[language].description14}</li>
                            </ul>
                            
                            
                            <b>{TextData.carreer2[language].description15}</b>
                            <ul>
                                <li>{TextData.carreer2[language].description16}</li>
                                <li>{TextData.carreer2[language].description17}</li>
                            </ul>
                            

                            <div className="bewerbung-form-wrapper">
                                <form onSubmit={handleSubmit}>
                                <TextField
                                    name="name"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                    label={TextData.carreer2[language].textfield1}
                                    fullWidth
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="telefon"
                                    required
                                    value={formData.telefon}
                                    onChange={handleChange}
                                    label={TextData.carreer2[language].textfield2}
                                    fullWidth
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="email"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    label={TextData.carreer2[language].textfield3}
                                    fullWidth
                                    sx={{mb:2, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <TextField
                                    name="message"
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                    label={TextData.carreer2[language].textfield4}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{mb:3, boxSizing:'border-box', backgroundColor:"#fafafa", '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                                    />
                                    <p className="lebenslaufdesc">{TextData.carreer2[language].uploaddesc}</p>
                                    <TextField
                                    fullWidth
                                    name="file"
                                    type="file" 
                                    onChange={handleFileChange}
                                    sx={{mb:3,boxSizing:'border-box', backgroundColor:"#fafafa"}}
                                     />
                                    <FormControlLabel sx={{alignItems: 'start'}} control={<Checkbox sx={{paddingTop: '1px'}} required/>} label={MessagesData.contact[language].dsgvo} />
                                    <Button onClick={handleSubmissionMessage} type="submit" variant="contained" sx={{ mt: 4,
                                    '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                                    '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                                    color: 'black', bgcolor: '#DBB47E', padding: '8px 32px', boxShadow: 'none' }}>{TextData.carreer2[language].button}</Button>
                                    {submission === true ? (
                                        <p className="karriere-success">{MessagesData.contact[language].success}</p>
                                    ) : submission === false ? (
                                        <p className="karriere-error">{MessagesData.contact[language].error}</p>
                                    ) : null}
                                </form>
                            </div>
                        </Grid>
                    </Grid>
              </Container>
              </div>
              <Footer/>
        </div>
  )
}

export default Bewerbung