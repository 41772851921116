import React, {useEffect, useState, useContext} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { SourceContext } from '../../context/SourceContext';
import { DestinationContext } from '../../context/DestinationContext';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { TextData } from '../../source/textData';
import { LanguageContext } from "../../context/LanguageContext";


function InputItem ({type}) {

    const [placeholder, setPlaceholder] = useState(null);
    const {source, setSource} = useContext(SourceContext);
    const {destination, setDestination} = useContext(DestinationContext);
    const { language } = useContext(LanguageContext);



    useEffect(() => {
        type == 'source' ? setPlaceholder(TextData.buchung[language].formular.input1) : setPlaceholder(TextData.buchung[language].formular.input2);
    },[type, language]);

    const getLangAndLng = (place, type) => {

        const placeId = place.value.place_id;
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));

        service.getDetails({placeId},(place, status) => {
            if (status === 'OK' && place.geometry && place.geometry.location){
                if(type == 'source'){
                    setSource({
                        lat:place.geometry.location.lat(),
                        lng:place.geometry.location.lng(),
                        name:place.formatted_address,
                        label:place.name,
                    })
                }else{
                    setDestination({
                        lat:place.geometry.location.lat(),
                        lng:place.geometry.location.lng(),
                        name:place.formatted_address,
                        label:place.name,
                    })
                }
            }
        })
    }

    useEffect(()=> {

    },[])
    
    const defaultInputValue = type === 'source' ? (source ? source.label : '') : (destination ? destination.label : '');

    return(
        <Box className="inputitem-section" component="section" display="flex"  alignItems="center" sx={{boxSizing:'border-box',bgcolor:"#fafafa", pl: 1, border: 'none', width:'100%' }}>
            <FmdGoodOutlinedIcon/>
            <div className="inputitem-wrapper">
                <label className="inputitem-label">{type == 'source' ? TextData.searchSection[language].input1 : TextData.searchSection[language].input2}</label>
                <GooglePlacesAutocomplete 
                    selectProps={{ 
                    defaultInputValue: defaultInputValue,
                    onChange: (place) => {
                        getLangAndLng(place, type);
                    }, 
                    isClearable: false, 
                    className: "ga-autocomplete",
                    placeholder:placeholder,
                    components:{DropdownIndicator: false},
                    styles:{
                        control:(provided) => ({
                            ...provided,
                            backgroundColor: '#00ffff00',
                            border: 'none'
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontWeight: 400,
                            fontFamily: "Roboto, sans-serif", 
                        })
                    },

                    }} 
                    required
                    />
            </div>
        </Box>
    );
};

export default InputItem;