import React from 'react';
import Booking from '../booking/Booking';
import './banner.scss';

const Banner = () => {
    return(
    <div className="banner">
        <video className="video" autoPlay muted playsInline loop src="/assets/home/header-video-2.mp4" preload="auto"></video>
        <div className="banner-inner">
            <Booking/>
        </div>
    </div>
    );
}
    
export default Banner;