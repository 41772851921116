import React, {useContext} from 'react'
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './homeContent.scss';
import { TextData } from '../../source/textData';
import { LanguageContext } from "../../context/LanguageContext";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function HomeContent() {

  const { language } = useContext(LanguageContext);

  return (
    <section className="homeContent">
        <Container maxWidth="lg">
            <Grid container sx={{'@media (min-width:0px) and (max-width:1280px)': {rowGap: 4}, '@media (min-width:1280px)': {rowGap: 10}}}>
                <Grid item xs={12} md={5} order={{xs: 1, lg: 1}}>
                    <div className="homeContent-card">
                        <h2>{TextData.homeContent[language].section1.title}</h2>
                        <p>{TextData.homeContent[language].section1.description}</p>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section1.service1_1}</b>{TextData.homeContent[language].section1.service1_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section1.service2_1}</b>{TextData.homeContent[language].section1.service2_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section1.service3_1}</b>{TextData.homeContent[language].section1.service3_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section1.service4_1}</b>{TextData.homeContent[language].section1.service4_2}</p></div>
                    </div>
                </Grid>
                <Grid item xs={12} md={1} order={{xs: 2, lg: 2}}>
                </Grid>
                <Grid item xs={12} md={6} order={{xs: 3, lg: 3}}>
                    <div className="homeContent-img">
                        <img src="/assets/home/chauffeurservice-1_neu.jpg" alt="Chauffeurservice München"/>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} order={{xs: 6, md: 4, lg: 4}}>
                    <div className="homeContent-img">
                        <img src="/assets/home/chauffeurservice-2_neu.jpg" alt="Limousinenservice München"/>
                    </div>
                </Grid>
                <Grid item xs={12} md={1} order={{xs: 5, md: 5, lg: 5}}>
                </Grid>
                <Grid item xs={12} md={5} order={{xs: 4, md: 6, lg: 6}}>
                    <div className="homeContent-card">
                        <h2>{TextData.homeContent[language].section2.title}</h2>
                        <p>{TextData.homeContent[language].section2.description}</p>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section2.service1_1}</b>{TextData.homeContent[language].section2.service1_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section2.service2_1}</b>{TextData.homeContent[language].section2.service2_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section2.service3_1}</b>{TextData.homeContent[language].section2.service3_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section2.service4_1}</b>{TextData.homeContent[language].section2.service4_2}</p></div>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} order={{xs: 7, lg: 7}}>
                    <div className="homeContent-card">
                        <h2>{TextData.homeContent[language].section3.title}</h2>
                        <p>{TextData.homeContent[language].section3.description}</p>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section3.service1_1}</b>{TextData.homeContent[language].section3.service1_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section3.service2_1}</b>{TextData.homeContent[language].section3.service2_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section3.service3_1}</b>{TextData.homeContent[language].section3.service3_2}</p></div>
                            <div className="homeContent-items"><ArrowForwardIcon/><p><b>{TextData.homeContent[language].section3.service4_1}</b>{TextData.homeContent[language].section3.service4_2}</p></div>
                    </div>
                </Grid>
                <Grid item xs={12} md={1} order={{xs: 8, lg: 8}}>
                </Grid>
                <Grid item xs={12} md={6} order={{xs: 9, lg: 9}}>
                    <div className="homeContent-img">
                        <img src="/assets/home/chauffeurservice-3_neu.jpg" alt="VIP Driver's GmbH München"/>
                    </div>
                </Grid>
            </Grid>
            
        </Container>
    </section>
  )
}

export default HomeContent