import { createContext, useEffect, useReducer } from "react";
import CookiesReducer from "./CookiesReducer";

const INITIAL_STATE = {
    cookiebanner: JSON.parse(localStorage.getItem("cookiebanner")) || false,
};

export const CookiesContext = createContext(INITIAL_STATE);

export const CookiesContextProvider = ({ children }) => {
  const [state, setCookieBanner] = useReducer(CookiesReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("cookiebanner", JSON.stringify(state.cookiebanner));
  }, [state.cookiebanner]);

  return (
    <CookiesContext.Provider value={{ cookiebanner: state.cookiebanner, setCookieBanner }}>
      {children}
    </CookiesContext.Provider>
  );
};