import React, {useState, useContext} from 'react';
import './leistungen.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { TextData } from '../../source/textData'
import { LanguageContext } from "../../context/LanguageContext";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



function Leistungen() {

const { language } = useContext(LanguageContext);


  return (
          <div className="leistungen">
              <NavbarWeb scrolled={true}/>
              <div className="leistungenContainer">
              <Container maxWidth="lg" >

                    <Grid container sx={{'@media (min-width:0px) and (max-width:1280px)': {rowGap: 2}, '@media (min-width:1280px)': {rowGap: 10}}}> 
                              <Grid item xs={12} md={5} order={{xs: 1, lg: 1}}>
                                  <div className="leistungenContent-card">
                                      <h2>{TextData.services[language].title}</h2>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service1}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service2}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service3}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service4}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service5}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service6}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service7}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service8}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service9}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service10}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service11}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service12}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service13}</p></div>
                                      <div className="leistungenContent-items"><ArrowForwardIcon/><p>{TextData.services[language].service14}</p></div>
                                      {/*<ul>
                                        <li>Umfassender Chauffeurservice bei VIP Driver’s GmbH mit professionellem Reisemanagement</li>
                                        <li>Engagiertes Team, das individuelle Reisebedürfnisse versteht und die gesamte Organisation von Ankunft bis Abreise übernimmt</li>
                                        </ul>*/}
                                  </div>
                              </Grid>
                              <Grid item xs={12} md={1} order={{xs: 2, lg: 2}}>
                              </Grid>
                              <Grid item xs={12} md={6} order={{xs: 3, lg: 3}}>
                                  <div className="leistungenContent-img">
                                      <img src="/assets/home/chauffeurservice-1_neu.jpg" alt="Chauffeurservice Leistungen"/>
                                  </div>
                              </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{paddingTop: '80px'}}>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <RoomOutlinedIcon sx={{ fontSize: 32 }}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card1.title}</h3>
                                <p>{TextData.services[language].cards.card1.description}</p>
                            </div>
                            </Grid>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <SignpostOutlinedIcon sx={{ fontSize: 32 }}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card2.title}</h3>
                                <p>{TextData.services[language].cards.card2.description}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <DirectionsCarFilledOutlinedIcon sx={{ fontSize: 32 }}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card3.title}</h3>
                                <p>{TextData.services[language].cards.card3.description}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <PaymentsOutlinedIcon  sx={{ fontSize: 32}}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card4.title}</h3>
                                <p>{TextData.services[language].cards.card4.description}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <RepeatOutlinedIcon sx={{ fontSize: 32 }}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card5.title}</h3>
                                <p>{TextData.services[language].cards.card5.description}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'left' }}>
                            <div className="leistungenContent-card2">
                                <GTranslateIcon sx={{ fontSize: 32 }}/>
                                <h3 className="leistungen-items-titel">{TextData.services[language].cards.card6.title}</h3>
                                <p>{TextData.services[language].cards.card6.description}</p>
                            </div>
                        </Grid>
                    </Grid>
                    
              </Container>
              </div>
              <Footer/>
        </div>
  )
}

export default Leistungen