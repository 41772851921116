import React, {useContext}  from 'react';
import './datenschutz.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import { LegalData } from '../../source/legalData';
import { LanguageContext } from "../../context/LanguageContext";


function Datenschutz() {

  const { language } = useContext(LanguageContext);

  return (
    <div className="datenschutz">
        <NavbarWeb scrolled={true}/>
        <div className="datenschutzContainer">
        <Container maxWidth="lg" >
            <h1>{LegalData.dataprivacy[language].title}</h1>
            <h2>{LegalData.dataprivacy[language].subtitle1}</h2>
            
            <h3 className="nomargin">{LegalData.dataprivacy[language].subtitle1_1}</h3>
            <p>{LegalData.dataprivacy[language].subtitle1_1_desc1}</p>
            
            <h3>{LegalData.dataprivacy[language].subtitle1_2}</h3>
            <p className="space-bottom">{LegalData.dataprivacy[language].subtitle1_2_desc1}</p>

            <p><b>{LegalData.dataprivacy[language].subtitle1_3}</b></p>
            <p>{LegalData.dataprivacy[language].subtitle1_3_desc1}</p>
            
            <p><b>{LegalData.dataprivacy[language].subtitle1_4}</b></p>
            <p>{LegalData.dataprivacy[language].subtitle1_4_desc1}</p>

            <p><b>{LegalData.dataprivacy[language].subtitle1_5}</b></p>
            <p>{LegalData.dataprivacy[language].subtitle1_5_desc1}</p>

            <h2>{LegalData.dataprivacy[language].subtitle2}</h2>
            <p><b>{LegalData.dataprivacy[language].subtitle2_1}</b></p>
            <p>{LegalData.dataprivacy[language].subtitle2_1_desc1}</p>
            <p>{LegalData.dataprivacy[language].subtitle2_1_desc2}</p>
            <p>{LegalData.dataprivacy[language].subtitle2_1_desc3}</p>
            <p className="space-bottom">{LegalData.dataprivacy[language].subtitle2_1_desc4}</p>

            <p><b>{LegalData.dataprivacy[language].subtitle2_2}</b></p>
            <p>{LegalData.dataprivacy[language].subtitle2_2_desc1}</p>
            
            <h2>{LegalData.dataprivacy[language].subtitle3}</h2>
            <h3 className="nomargin">{LegalData.dataprivacy[language].subtitle3_1}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_1_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_2}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_2_desc1}</p>
            <p>{LegalData.dataprivacy[language].subtitle3_2_desc2}</p>
            <p>{LegalData.dataprivacy[language].subtitle3_2_desc3}</p>
            <p className="space-bottom">{LegalData.dataprivacy[language].subtitle3_2_desc4}</p>
            <p>{LegalData.dataprivacy[language].subtitle3_2_desc5}</p>
            <p>{LegalData.dataprivacy[language].subtitle3_2_desc6}</p>



            <h3>{LegalData.dataprivacy[language].subtitle3_3}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_3_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_4}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_4_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_5}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_5_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_6}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_6_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_7}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_7_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_8}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_8_desc1}</p>
            
            <h3>{LegalData.dataprivacy[language].subtitle3_9}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_9_desc1}</p>
            
            <h3>{LegalData.dataprivacy[language].subtitle3_10}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_10_desc1}</p>
            
            <h3>{LegalData.dataprivacy[language].subtitle3_11}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_11_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle3_12}</h3>
            <p>{LegalData.dataprivacy[language].subtitle3_12_desc1}</p>

            <h2>{LegalData.dataprivacy[language].subtitle4}</h2>
            <h3 className="nomargin">{LegalData.dataprivacy[language].subtitle4_1}</h3>
            <p>{LegalData.dataprivacy[language].subtitle4_1_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle4_2}</h3>
            <p>{LegalData.dataprivacy[language].subtitle4_2_desc1}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc2}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc3}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc4}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc5}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc6}</p>
                <p>{LegalData.dataprivacy[language].subtitle4_2_desc7}</p>
            <p>{LegalData.dataprivacy[language].subtitle4_2_desc8}</p>
            
            <h3>{LegalData.dataprivacy[language].subtitle4_3}</h3>
            <p>{LegalData.dataprivacy[language].subtitle4_3_desc1}</p>

            <h3>{LegalData.dataprivacy[language].subtitle4_4}</h3>
            <p>{LegalData.dataprivacy[language].subtitle4_4_desc1}</p>

            <h2>{LegalData.dataprivacy[language].subtitle5}</h2>
            <p>{LegalData.dataprivacy[language].subtitle5_desc1}</p>
        </Container>
        </div>
        <Footer/>
    </div>
  )
}

export default Datenschutz