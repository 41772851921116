import React, {useContext} from 'react';
import './karriere.scss';
import Footer from '../../components/footer/Footer';
import NavbarWeb from "../../components/navbarWeb/NavbarWeb";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { TextData } from '../../source/textData'
import { LanguageContext } from "../../context/LanguageContext";


function Karriere() {
      const { language } = useContext(LanguageContext);

  return (
        <div className="karriere">
              <NavbarWeb scrolled={true}/>
              <div className="karriereContainer">
              <Container maxWidth="lg" >
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
                        <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <h1 className="services-section-common-main-heading">{TextData.carreer1[language].title}</h1>
                            <p>{TextData.carreer1[language].description}</p>
                              <Link to="/bewerbung">
                              <Button variant="contained" sx={{
                                    '&:hover': {backgroundColor: '#DBB47E', boxShadow: 'none',}, 
                                    '&:active': {backgroundColor: '#DBB47E', boxShadow: 'none'}, 
                                    color: 'black', bgcolor: '#DBB47E', padding: '8px 32px', boxShadow: 'none' }}>{TextData.carreer1[language].button}</Button>
                              </Link>
                        </Grid>
                    </Grid>
              </Container>
              </div>
              <Footer/>
        </div>
  )
}

export default Karriere