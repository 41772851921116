export const MessagesData = {
    contact: {
        DE: {
            dsgvo: "Ich willige ein, dass diese Website meine übermittelten Informationen speichert, sodass meine Anfrage beantwortet werden kann.",
            success: "Vielen Dank für Ihre Nachricht! Wir werden Sie so schnell wie möglich kontaktieren.",
            error: "Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an: kontakt@vipdrivers.de"
        },
        EN: {
            dsgvo: "I consent to this website storing my submitted information so that my inquiry can be addressed.",
            success: "Thank you for your message! We will contact you as soon as possible.",
            error: "An error occurred. Please contact: kontakt@vipdrivers.de"
        }
    },

    buchung: {
        DE: {
            source_error: "Bitte füllen Sie das Feld 'Abholort' vollständig aus.",
            source_and_destination_error: "Bitte füllen Sie die Felder 'Ihr Abholort' und 'Ihr Zielort' vollständig aus."
        },
        EN: {
            source_error: "Please fill out the 'Pick-up location' field completely.",
            source_and_destination_error: "Please fill in the fields 'Your Pickup Location' and 'Your Destination' completely."
        }
    },

    discount: {
        DE: {
            success: "Der Rabattcode wurde erfolgreich angewendet!",
            error: "Dieser Rabattcode ist ungültig oder wurde nicht erkannt.",
            company_error: "Hinweis: Bitte geben Sie einen gültigen Firmennamen an."
        },
        EN: {
            success: "The discount code has been applied successfully!",
            error: "This discount code is invalid or not recognized.",
            company_error: "Note: Please provide a valid company name."
        }
    }
};