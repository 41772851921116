import React, {useContext} from 'react';
import './faq.scss';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { TextData } from '../../source/textData'
import { LanguageContext } from "../../context/LanguageContext";


  const StyledAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const StyledAccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#000',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      //marginLeft: theme.spacing(1),
    },
  }));
  
  const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: `${theme.spacing(2)}`,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  }));

  const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
    fontFamily: '"Mukta", sans-serif',
    lineHeight: `30px`,
    fontSize: `20px`,
    fontWeight: '400',
    color: `##000`
  }));

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontFamily: '"Mukta", sans-serif',
    lineHeight: `28px`,
    fontSize: `18px`,
    fontWeight: '300',
    color: `#4b5566`
  }));
  
  export default function Faq() {
    const [expanded, setExpanded] = React.useState('panel1');
    const [moreButton, setMoreButton] = React.useState(false);
    const { language } = useContext(LanguageContext);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    const accordionItems = TextData.accordionData[language].panels;
    const firstFourPanels = Object.entries(accordionItems).slice(0, 4); // Slice the first four panels
    const additionalPanels = Object.entries(accordionItems).slice(4); // Slice the remaining panels


    return (
      <section className="home-faq" id="faq">
      <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <h3>{TextData.accordionData[language].heading.title}</h3>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <div>
        {firstFourPanels.map(([panelKey, panelData]) => (
        <StyledAccordion key={panelKey} expanded={expanded === panelKey} onChange={handleChange(panelKey)} sx={{pt:1, pb:1}}>
          <StyledAccordionSummary aria-controls={`${panelKey}-content`} id={`${panelKey}-header`}>
            <StyledTypographyHeading>{panelData.title}</StyledTypographyHeading>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <StyledTypography>{panelData.description}</StyledTypography>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
        <div className={moreButton ? "additional-content show" : "additional-content"}>
        {additionalPanels.map(([panelKey, panelData]) => (
          <StyledAccordion key={panelKey} expanded={expanded === panelKey} onChange={handleChange(panelKey)} sx={{pt:1, pb:1}}>
            <StyledAccordionSummary aria-controls={`${panelKey}-content`} id={`${panelKey}-header`}>
              <StyledTypographyHeading>{panelData.title}</StyledTypographyHeading>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <StyledTypography>{panelData.description}</StyledTypography>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
        </div>
      </div>
      <div className="show-more-faq-btn">
        <Button variant="outlined" style={{ color: 'black', borderColor: 'black' }} onClick={() => setMoreButton(!moreButton)}>{moreButton ? TextData.accordionButton[language].less : TextData.accordionButton[language].more}</Button>
      </div>
      </Grid>
      </Grid>
      </Container>
      </section>
    );
  
}

//export default Faq