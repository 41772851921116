const LanguageReducer = (state, action) => {
    switch (action.type) {
      case "DE": {
        return {
          language: 'DE',
        };
      }
      case "EN": {
        return {
          language: 'EN',
        };
      }
      default:
        return state;
    }
  };
  
  export default LanguageReducer;