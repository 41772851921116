import {React, useState, useContext} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { CookiesContext } from '../../context/CookiesContext';



function Cookiebanner() {

const { cookiebanner, setCookieBanner } = useContext(CookiesContext);

  const handleClose = () => {
    setCookieBanner({ type: "accept" });
  };

  return (
        <Dialog
            open={cookiebanner ? false : true}
            onClose={handleClose}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Die Webseite verwendet Cookies"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Diese Website verwendet Cookies, um Ihr Erlebnis zu verbessern. 
            Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies gemäß unserer Cookie-Richtlinie zu. 
            Bitte beachten Sie, dass wir keine Marketing-Cookies verwenden. 
            Vielen Dank für Ihren Besuch auf unserer Website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
           {/*<Button onClick={handleClose}>Disagree</Button>*/}
          <Button sx={{marginRight: '20px'}} onClick={handleClose}>
            Akzeptieren
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default Cookiebanner